
.page-section {
  padding: 6rem 0;
}

.page-section .page-section-heading {
  font-size: 2.25rem;
  line-height: 2rem;
}

.vantagens .Cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  }
  
  .vantagens .Card {
  border: 3px solid;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  
  display: flex;
  flex-basis: 22%;
  flex-direction: column;
  
  }
  
  .vantagens .Card .Title {
  padding: 5px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;    
  }
  
  .vantagens .Card .Content {
  flex-grow: 1;
  background-color: #fff;
  color: #000;
  padding: 10px;
  text-align: center;
  }


  .caracteristicas .Cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .caracteristicas .Card {
      border: 3px solid;
      border-radius: 10px;
      overflow: hidden;
      margin: 10px;
      
      display: flex;
      flex-basis: 24%;
      flex-direction: row;
      align-items: center;
  }

  .caracteristicas .Card .Title {
    padding: 5px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;    
    }
    
    .caracteristicas .Card .Content {
    flex-grow: 1;
    background-color: #fff;
    color: #000;
    padding: 10px;
    text-align: center;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  

.divider-custom {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-custom .divider-custom-line {
  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: #2c3e50;
  border-radius: 1rem;
  border-color: #2c3e50 !important;
}

.divider-custom .divider-custom-line:first-child {
  margin-right: 1rem;
}

.divider-custom .divider-custom-line:last-child {
  margin-left: 1rem;
}

.divider-custom .divider-custom-icon {
  color: #2c3e50 !important;
  font-size: 2rem;
}

.divider-custom.divider-light .divider-custom-line {
  background-color: #fff;
}

.divider-custom.divider-light .divider-custom-icon {
  color: #fff !important;
}

.btn-xl {
  padding: 1rem 1.75rem;
  font-size: 1.25rem;
}

.btn-social {
  border-radius: 100%;
  display: inline-flex;
  width: 3.25rem;
  height: 3.25rem;
  font-size: 1.25rem;
  justify-content: center;
  align-items: center;
}

.scroll-to-top-x {
  position: fixed;
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
}

.scroll-to-top-x button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #2c3e5050;
  line-height: 3.1rem;
  padding: 15px;
  margin: -5px;
  border: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.scroll-to-top {
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
  display: none;
}

.scroll-to-top button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #2c3e5050;
  line-height: 3.1rem;
  padding: 15px;
  margin: -5px;
  border: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}


#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
}

#mainNav .navbar-brand {
  color: #fff;
}

#mainNav .navbar-nav {
  margin-top: 1rem;
}

#mainNav .navbar-nav li.nav-item a.nav-link {
  color: #fff;
}

#mainNav .navbar-nav li.nav-item a.nav-link:hover {
  color: #39c643;
}

#mainNav .navbar-nav li.nav-item a.nav-link:active, #mainNav .navbar-nav li.nav-item a.nav-link:focus {
  color: #fff;
}

#mainNav .navbar-nav li.nav-item a.nav-link.active {
  color: #fcff00;
}

#mainNav .navbar-toggler {
  font-size: 80%;
  padding: 0.8rem;
}


#mainNav.navbar-shrink {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #085429; 
}

#mainNav.navbar-shrink .navbar-brand {
  font-size: 1.5em;
}

.image-gallery{background: black; width: 100%;}

.masthead {
  background-image: url('http://brazilcats.com/wp-content/themes/brazilcats/slides/t1.jpg');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
  padding-top: calc(6rem + 74px);
  padding-bottom: 6rem;
}

.masthead .masthead-heading {
  font-size: 2.75rem;
  line-height: 2.75rem;
}

.masthead .masthead-subheading {
  font-size: 1.25rem;
}

.masthead .masthead-avatar {
  width: 15rem;
}


.servicos {
}

.servicos .servico-item {
  cursor: pointer;
  position: relative;
  display: block;
  text-align: center;
  max-width: 25rem;
  margin-bottom: 30px;
  border-radius: 0.5rem;
  overflow: hidden;
}

.servicos .servico-item .servico-item-caption {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background-color: rgba(26, 188, 156, 0.9);
}

.servicos .servico-item .servico-item-caption:hover {
  opacity: 1;
}

.servicos .servico-item .servico-item-caption .servico-item-caption-content {
  font-size: 1.5rem;
}

.btnjogo {
  padding: 5px;
  background-color: #028ac4;
}

.btnjogo .botao{
  width: 64px;
}

.caracteristicas {
  background: #ad5389;  /* fallback for old browsers */
  background: -webkit-linear-gradient(0deg, #3c1053, #ad5389);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(0deg, #3c1053, #ad5389); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    margin-bottom: -30px;
}

.contact {
}

.portfolio {
  margin-bottom: -30px;
}

.vantagens {
  padding: 2rem 0;
  width: 100%;
  background-repeat: repeat;
  background-position: center 101%;
  background-size: 100vw;
}

.seo {
  width: 100%;
  background-repeat: repeat;
  background-position: center 101%;
  background-size: 100vw;
}

.portfolio .portfolio-item {
  position: relative;
  display: block;
  text-align: center;
  max-width: 25rem;
  margin-bottom: 30px;
  border-radius: 0.5rem;
  overflow: hidden;
  }

.portfolio .portfolio-item img {
  width: 90%;
  -webkit-box-shadow: 0px 0px 20px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px -8px rgba(0,0,0,0.75);
}

.portfolio .portfolio-item .portfolio-item-caption {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background-color: rgba(26, 188, 156, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1;
}

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
  font-size: 1.5rem;
}

.portfolio-modal .portfolio-modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
}

.portfolio #page-numbers {
  text-align: center;
  padding: 0;
}

.portfolio #page-numbers li {
  margin: 5px;
  cursor: pointer;
}

.portfolio-modal .close {
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 1rem;
  font-size: 3rem;
  line-height: 3rem;
  color: #1abc9c;
  opacity: 1;
}

.floating-label-form-group {
  position: relative;
  border-bottom: 1px solid #e9ecef;
}

.floating-label-form-group input,
.floating-label-form-group textarea {
  font-size: 1.5em;
  position: relative;
  z-index: 1;
  padding-right: 0;
  padding-left: 0;
  resize: none;
  border: none;
  border-radius: 0;
  background: none;
  box-shadow: none !important;
}

.floating-label-form-group label {
  font-size: 0.85em;
  line-height: 1.764705882em;
  position: relative;
  z-index: 0;
  top: 2em;
  display: block;
  margin: 0;
  transition: top 0.3s ease, opacity 0.3s ease;
  vertical-align: middle;
  vertical-align: baseline;
  opacity: 0;
}

.floating-label-form-group:not(:first-child) {
  padding-left: 14px;
  border-left: 1px solid #e9ecef;
}

.floating-label-form-group-with-value label {
  top: 0;
  opacity: 1;
}

.floating-label-form-group-with-focus label {
  color: #1abc9c;
}

form .row:first-child .floating-label-form-group {
  border-top: 1px solid #e9ecef;
}

.footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #6e322e;
  border-top: 2px dotted #fff;
  color: #fff;
}

.footer a:link {
  color: #46DBD1;
}

.footer a:hover {
  text-decoration: none;
}


.copyright {
  background-color: #461e1c;
}

.center{
	max-width: 1100px;
	margin: 0 auto;
	padding:0 2%;
}

.clear{clear: both;}

.w50{float: left;width: 50%;padding:0 15px;}

.stars{
  text-align: center;
}

.stars i{
	font-size: 20px;
  color:#ffffff;
}

.circle{
	position: absolute;
	left: 50%;
	margin-top:-20px;
	transform: translateX(-50%);
   width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 10px 25px 0 rgba(37, 15, 139, 0.1);
}

.circle i{
	position: relative;
	top: 5px;
	font-size: 30px;
	color:#1c4aa6;
}

section.clientes-slider{
	padding:60px 0;
}

section.clientes-slider img{padding:0 20px;}

/****SLICK SLIDER****/

.lstCaracteristicas{
	list-style-type: none;
	text-align: left;
	position: relative;
	margin-top:10px;
}

.lstCaracteristicas li{
	margin: 0 7px;
}


.slick-dotted ul{
	list-style-type: none;
	text-align: center;
	position: relative;
	margin-top:10px;
}

.slick-dotted li{
	display: inline-block;
	margin: 0 7px;
}

.slick-dotted button:focus{
	outline: 0;
}

.slick-dotted li button{
	width: 16px;
	height: 16px;
	border-radius: 8px;
	color: #d8d8d8;
	border-width: 0;
	background: #d8d8d8;
	opacity: 1;
	cursor: pointer;
}

.slick-dots li button {
}

li.slick-active button{
	background: #0c3f8a;
	color: #0c3f8a;
}

.slick-slide:focus { outline: none; }

/**END SLICK**/

section.depoimentos{
	padding: 100px 0;
	background-color: #17a2b8;
}

section.depoimentos h2{
	text-align: center;
	color: white;
}

.depoimentos-box{
	margin:10px auto;
	max-width: 800px;
  width: 90%;
	display: block;
}
.depoimento-single{
  width: 100%;
  display: inline-block; 
  padding: 15px;
  box-shadow: 0 10px 25px 0 rgba(37, 15, 139, 0.1);
  border:1px dotted #ffffff;
  margin: 0 15px;
}
.depoimento-single p{text-align: center;margin-bottom: 15px;color: white;}
.depoimento-single img{
	width: 70px;
	height: 70px;
	display: block;
	margin: 10px auto;
	border-radius: 50%;
}


.title {
  position: relative;
  color: #fff;
  text-align: center;
  font-weight: 700;
}
.mb-5 {
  margin-bottom: 3rem!important;
}

section.video {
  background-color: transparent;
  background-image: linear-gradient(180deg,#031d0e 58%,#010703 100%);
  transition: background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
  padding: 50px 10px 15px 10px;
  position: relative;
  margin-bottom: -20px;
}

.shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.svgblue {
}

.svgoutra {
  background-color: #0cFF26;
}

.shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.shape-top {
  top: -1px;
}

section.video .shape-top svg {
  width: calc(300% + 1.3px);
  height: 500px;
}

.container-whapper {
  max-width: 750px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
}

section.video .video-container {
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0 4px 10px 0 #000;
}

section.video .video-wrapper {
  position: relative;
}

section.video .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
  background-color: #000;
}

section.video .shape-fill, .cronograma .shape-fill, .depoimentos .shape-fill {
  fill: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

section.video .shape-top .shape-fill {
  fill: #085429;
}

section.video-action {
  position: relative;
  background-color: #2c2c54;
  transition: background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
  padding: 0 0 100px 0;
}

.video-action .shape-bottom , .cronograma .shape-bottom, .depoimentos .shape-bottom {
  bottom: -1px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.video-action .shape-bottom svg, .cronograma .shape-bottom svg, .depoimentos .shape-bottom svg {
  width: calc(112% + 1.3px);
  height: 50px;
}

section.video-action .shape-fill {
  fill: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

@media (min-width: 0px) {

  #about {
    padding: 0px;
  }

}

@media (min-width: 300px) {

  .vantagens .Card {
    flex-basis: 100%;
  }

  .caracteristicas .Card {
    flex-basis: 100%;
  }

  #svg {
    margin-bottom: 40px;
  }

  .space {
    margin-top: 140px;
  }

  .mainNav { 
    background-color: #085429; 
  }    

  #mainNav .navbar-nav
  {
    width: 100%;
    text-align: center;
  }
}


@media screen and (min-width: 768px){
	.col-footer{margin-bottom: 30px;width: 100% !important;text-align: center !important;}
	.box-single-diferenciais{width: 100%;}
	.w50{width: 100%;}
	.img-time{margin-top:30px;}
	.menu-desktop{display: none;}
	.menu-mobile{display: block;}

  .vantagens .Card {
    flex-basis: 45%;
  }

  .caracteristicas .Card {
    flex-basis: 45%;
  }

}

@media (min-width: 992px) {

  .page-section .page-section-heading {
    font-size: 3rem;
    line-height: 2.5rem;
  }

  .vantagens .Card {
    flex-basis: 22%;
  }

  .caracteristicas .Card {
    flex-basis: 24%;
  }

  #svg {
    margin-bottom: 0;
  }

  .space {
    margin-top: 0px;
  }

  #mainNav {
    background:none; 
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: padding-top 0.3s, padding-bottom 0.3s;
  }
  #mainNav .navbar-brand {
    font-size: 1.75em;
    transition: font-size 0.3s;
  }
  #mainNav .navbar-nav {
    margin-top: 0;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active {
    color: #fff;
    border-bottom: 3px solid #10ab53;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active:active, #mainNav .navbar-nav > li.nav-item > a.nav-link.active:focus, #mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover {
    color: #fff;
    border-bottom: 3px solid #10ab53;
  }

  #mainNav .navbar-nav {
    width: auto;
  }

  .masthead {
    padding-top: calc(6rem + 104px);
    padding-bottom: 6rem;
  }
  .masthead .masthead-heading {
    font-size: 4rem;
    line-height: 3.5rem;
  }
  .masthead .masthead-subheading {
    font-size: 1.5rem;
  }

  .portfolio-modal .portfolio-modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }

}

