    .accordion {
    box-sizing: border-box;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    width: 100%;
}

.accordion-select {
    cursor: pointer;
    margin: 0;
    opacity: 0;
    z-index: 1;
}

.accordion-title {
    position: relative;
}

.accordion-title:not(:nth-last-child(2))::after {
    border: 1px solid transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.accordion-title span {
    bottom: 0px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    white-space: nowrap;
    width: 100%;
}

.accordion-content {
    box-sizing: border-box;
    overflow: auto;
    position: relative;
    transition: margin 0.3s ease 0.1s;
}

.accordion-select:checked + .accordion-title + .accordion-content {
    margin-bottom: 0;
    margin-right: 0;
}

/* Generated styles starts here */

.accordion {
    border-color: #dedede;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    flex-direction: column;
    height: auto;
} 

.accordion-title,
.accordion-select  {
    background-color: #ffffff;
    color: #7f8787;
    width: 100%;
    height: 65px;
    font-size: 15px;
}

.accordion-select {
    margin-bottom: -65px;
    margin-right: 0;
}

.accordion-title:not(:nth-last-child(2))::after {
    border-bottom-color: rgb(234, 234, 234);
    border-right-color: transparent;
} 

.accordion-select:hover + .accordion-title,
.accordion-select:checked + .accordion-title {
    background-color: #ffffff;
} 

 .accordion-title span  {	
    transform: rotate(0deg);
    -ms-writing-mode: lr-tb;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
    padding-left: 33px;
    padding-right: 33px;
    line-height: 65px;
} 

.accordion-content {
    background-color: #f7f7f7;
    color: #7f8787;
    height: 280px;
    margin-bottom: -280px;
    margin-right: 0;
    padding: 30px;
    width: 100%;
} 